import React from 'react';
import {IoQrCode, IoCalendar, IoNotifications, IoWallet, IoHome} from 'react-icons/io5';
import classNames from 'classnames';

import {SectionTilesProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
   className,
   topOuterDivider,
   bottomOuterDivider,
   topDivider,
   bottomDivider,
   hasBgColor,
   invertColor,
   pushLeft,
   ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Seguridad al alcance de tu mano',
    paragraph: 'Somos HomeGate, la solución perfecta para el control de accesos y gestión de tu residencial o edificio departamental.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content mb-32"/>
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <IoQrCode className="text-color-primary" size={40}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    Accesos QR
                  </h4>
                  <p className="m-0 text-sm">
                    Crea accesos QR para tus visitas o proveedores de servicios.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <IoNotifications className="text-color-primary" size={40}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    Notificaciones
                  </h4>
                  <p className="m-0 text-sm">
                    Recibe en tiempo real notificaciones de ingreso o egreso.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <IoCalendar className="text-color-primary" size={40}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    Reservas
                  </h4>
                  <p className="m-0 text-sm">
                    Haz reservaciones de las áreas comunes.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <IoWallet className="text-color-primary" size={40}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    Pagos mantenimiento
                  </h4>
                  <p className="m-0 text-sm">
                    Paga de manera segura tu cuota de mantenimiento.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <IoHome className="text-color-primary"  size={40}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    Administración web
                  </h4>
                  <p className="m-0 text-sm">
                    Gestiona usuarios, cuotas, áreas comunes, pagos, todo desde tu portal web de administración.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
