import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesSplitTwo from '../components/sections/FeaturesSplitTwo';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Image from "../components/elements/Image";

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <FeaturesSplitTwo invertMobile topDivider imageFill className="illustration-section-02" />
      <GenericSection topDivider>
        <SectionHeader data={{
          title: 'Únete a la revolución en seguridad',
          paragraph: 'Próximamante'
        }} className="center-content" />
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Image width={200} src="https://s3.us-east-2.amazonaws.com/assets.cqurity.io/logos/logoHorizontal.png" />
        </div>
      </GenericSection>
    </>
  );
}

export default Home;
