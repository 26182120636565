import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Tecnología que simplemente funciona',
    paragraph: 'Hemos construido tu app HomeGate con la última tecnología en dispositivos móviles. Disponible para iOS y Android'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Seguridad
                </div>
                <h3 className="mt-0 mb-12">
                  Accesos QR
                </h3>
                <p className="m-0">
                  Dar acceso a tus visitas o proveedores de servicio debe ser fácil, tu app HomeGate te permite crear accesos mediante códigos QR para tus familiares, amigos o proveedores de servicios.
                </p>
              </div>
              <div style={{ width: '100%' }} className={
                classNames(
                  'center-content-mobile center-content reveal-from-bottom mb-52',
                )}
                data-reveal-container=".split-item">
                <Image
                  src="https://s3.us-east-2.amazonaws.com/assets.cqurity.io/mockups/access_detail.png"
                  alt="Features split 01"
                  width={300}
                />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Pagos
                  </div>
                <h3 className="mt-0 mb-12">
                  Que no se te pase
                </h3>
                <p className="m-0">
                  Realizar tu pago de mantenimiento nunca fue tan fácil, podrás pagar desde tu app HomeGate con tarjeta o generar una ficha de pago y depositar en cualquier tienda OXXO del país. Tu pago se verá reflejado al instante.
                </p>
              </div>
              <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }} className={
                classNames(
                  'center-content-mobile center-content reveal-from-bottom mb-52',
                )}
                   data-reveal-container=".split-item">
                <Image
                  src="https://s3.us-east-2.amazonaws.com/assets.cqurity.io/mockups/payments.png"
                  alt="Features split 01"
                  width={250}
                />
                <Image
                  src="https://s3.us-east-2.amazonaws.com/assets.cqurity.io/mockups/payment_detail.png"
                  alt="Features split 01"
                  width={250}
                />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Reservas
                </div>
                <h3 className="mt-0 mb-12">
                  Tus eventos sin contratiempos
                </h3>
                <p className="m-0">
                  Tu app HomeGate te permite reservar las áreas comunes de tu residencial o edificio.
                </p>
              </div>
              <div style={{ width: '100%' }} className={
                classNames(
                  'center-content-mobile center-content reveal-from-bottom mb-52',
                )}
                   data-reveal-container=".split-item">
                <Image
                  src="https://s3.us-east-2.amazonaws.com/assets.cqurity.io/mockups/create_reservation.png"
                  alt="Features split 03"
                  width={300} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
