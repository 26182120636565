import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '12px'
            }} className="mt-0 reveal-from-bottom" data-reveal-delay="200">
             <h2>Con</h2> <span className="text-color-primary"><Image width={250} src="https://s3.us-east-2.amazonaws.com/assets.cqurity.io/logos/logoHorizontal.png" /> </span>
            </div>
            <h1 className="mt-16 mb-52 reveal-from-bottom" data-reveal-delay="200"><span style={{ fontWeight: 500 }} className="text-color-primary">No te sientes... </span> estás seguro</h1>
          </div>
          <div className="hero-figure mb-52 reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <Image
              className="has-shadow"
              src="https://s3.us-east-2.amazonaws.com/assets.cqurity.io/illustrations/securityIllustration.png"
              alt="Hero"
              width={450}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
